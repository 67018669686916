.icons__footers {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 40px;
  font-size: 45px;
}

.discord__icon {
  margin-top: -9px;
  margin-left: -15px;
  cursor: pointer;
}

/* Social Icon */
.footer__icon {
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5px;
  cursor: pointer;
  margin: 2px;
  border-radius: 3px;
}

.footer__icon svg, .footer__icon img {
  width: 20px;
  height: 20px;
  fill: black;
}

.footer__icon > div {
  display: flex;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  background: #88c8e6;
  width: 100%;
  height: 100%;
  transition: cubic-bezier(0.215, 0.61, 0.355, 1);
  border: 1px solid rgba(255, 255, 255, 0.541);
}

.footer__icon > div > i {
  color: rgba(0, 0, 0, 0.801);
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.247);
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0rem;
}
ol,
ul {
  padding-left: 0rem;
}

.footer__icon > div > img {
}

.footer__icon:hover > div {
  background-image: var(--bgGradient);
}
